// This is a pinia store, where all the user data is stored and updated
export const useAuth = defineStore('useAuth', () => {
  const api = useApi()
  const user: Ref<User | undefined> = ref()

  // Getter for login state
  const isAuthenticated = computed(async () => {
    // Check if the user is there
    if (user.value) {
      return true
    }

    // If the user is not there, then call the api to retrieve the user
    try {
      await getUser()
      return true
    }
    catch (error) {
      if ((error as RestErr).status === 401 || (error as RestErr).status === 404) {
        console.log('User is not authenticated...')
        return false
      }
      // unknown error -> throw the error
      throw error
    }
  })

  // Gets the user from the cookie
  async function getUser() {
    const headers = useRequestHeaders(['cookie'])
    const data = await $fetch<User>(api.buildPath('/auth/users'), {
      headers,
      onResponseError({ response }) {
        const error = response._data as RestErr

        throw error
      },
      credentials: 'include',
    })

    user.value = data
  }

  // Saves the login data to the cookie
  function saveLoginData(data: LoginUserResponse) {
    // Refresh the cookie set by the login and save the user data
    user.value = data.user
  }

  // Set the user onboarding process status
  async function setUserOnboardingStatus(status: boolean) {
    const headers = useRequestHeaders(['cookie'])
    await $fetch(api.buildPath('/auth/onboarding'), {
      method: 'POST',
      headers,
      body: { onboardingDone: status },
      onResponseError({ response }) {
        const error = response?._data as RestErr
        throw error
      },
      credentials: 'include',
    })

    // Update the user object
    if (user.value) {
      user.value.firstLoginDone = status
    }
  }

  // Logs out the user
  async function logoutUser() {
    console.log('logging out user')
    const headers = useRequestHeaders(['cookie'])
    // Call the logout endpoint of the api
    await $fetch(api.buildPath('/auth/logout'), {
      headers,
      onResponseError({ response }) {
        const error = response?._data as RestErr
        throw error
      },
      credentials: 'include',
    })
    user.value = undefined
  }

  return {
    user,
    isAuthenticated,
    saveLoginData,
    getUser,
    setUserOnboardingStatus,
    logoutUser,
  }
})

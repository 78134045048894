export default defineAppConfig({
  umami: {
    version: 2,
    debug: true,
    ignoreLocalhost: true,
  },
  ui: {
    // notifications: {
    //   // Show toasts at the top right of the screen
    //   position: 'top-0 right-0',
    // },
    primary: 'prim',
    gray: 'slate',
    accent: 'acc',
    input: {
      default: {
        size: 'lg',
      },
      rounded: 'rounded-lg',
    },
    button: {
      rounded: 'rounded-lg',
      default: {
        size: 'lg',
      },
    },
    select: {
      default: {
        size: 'lg',
      },
      rounded: 'rounded-lg',
    },
    selectMenu: {
      default: {
        size: 'lg',
      },
      rounded: 'rounded-lg',
    },
  },
})

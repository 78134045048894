export default defineNuxtRouteMiddleware(async (to) => {
  // Array of routes that don't need authentication
  const unauthenticatedRoutes: string[] = ['/register', '/']

  // Check if the cookie is set
  try {
    const isAuthenticated = await useAuth().isAuthenticated

    // If the cookie is not set and the route is not in the unauthenticatedRoutes array, redirect to the login page
    if (!isAuthenticated && !unauthenticatedRoutes.includes(to.path)) {
      console.log('No session, redirecting to /')

      // useAuth().logoutUser()
      return navigateTo('/')
    }

    if (isAuthenticated && unauthenticatedRoutes.includes(to.path)) {
      const { user } = useAuth()

      if (!user) {
        return navigateTo('/')
      }

      if (!user.organisation) {
        return navigateTo('/organisation')
      }
      const domainDashboard = user.organisation.domain
      return navigateTo(`/${domainDashboard}`)
    }

    // If the user is authenticated and he already has an organisation, but the route is /organisation, redirect to the dashboard
    // if (isAuthenticated && to.path === '/organisation') {
    //   const { user } = useAuth()

    //   if (!user) {
    //     return navigateTo('/')
    //   }

    //   if (user?.organisation || user?.role !== 'admin') {
    //     return navigateTo(`/${user.organisation?.domain}`)
    //   }
    // }

    // if the user is authenticated and the route is /departments or /users or /organisation, but the user is not an admin or the first login is not done, redirect to the dashboard
    if (isAuthenticated && (to.path === '/departments' || to.path === '/users' || to.path === '/organisation')) {
      const { user } = useAuth()

      if (!user) {
        return navigateTo('/')
      }

      if (user?.role !== 'admin' || user.firstLoginDone) {
        return navigateTo(`/${user.organisation?.domain}`)
      }
    }
  }
  catch (error) {
    // navigateTo('/')
    console.warn('Error getting the cookie: ', error)
    throw error
  }
})

import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  // if (!sentry.enabled) {
  //   console.log('Sentry not enabled, skipping Sentry initialization from client')
  //   return
  // }

  if (!sentry.dsn) {
    console.warn('Sentry DSN not set, skipping Sentry initialization from client')
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.browserProfilingIntegration(),
    ],

    tracesSampleRate: 1.0, // Change in prod
    replaysSessionSampleRate: 0.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    profilesSampleRate: 0.1,
  })
})

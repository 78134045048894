import { default as _91id_933tWCISStpCMeta } from "/opt/render/project/src/pages/[domain]/blueprints/[id].vue?macro=true";
import { default as donedvXGgcsWVnMeta } from "/opt/render/project/src/pages/[domain]/blueprints/create/done.vue?macro=true";
import { default as indexv4OK9yqND2Meta } from "/opt/render/project/src/pages/[domain]/blueprints/create/index.vue?macro=true";
import { default as indexVZPTZy1tMtMeta } from "/opt/render/project/src/pages/[domain]/blueprints/index.vue?macro=true";
import { default as indexry2JKzRL0tMeta } from "/opt/render/project/src/pages/[domain]/index.vue?macro=true";
import { default as profilez1kc1tjKBkMeta } from "/opt/render/project/src/pages/[domain]/profile.vue?macro=true";
import { default as _91id_93CZuFSFUaydMeta } from "/opt/render/project/src/pages/[domain]/settings/departments/[id].vue?macro=true";
import { default as index5Cr4nlHza5Meta } from "/opt/render/project/src/pages/[domain]/settings/departments/index.vue?macro=true";
import { default as organisationhOU0PFhosvMeta } from "/opt/render/project/src/pages/[domain]/settings/organisation.vue?macro=true";
import { default as _91id_933RFgIImSuyMeta } from "/opt/render/project/src/pages/[domain]/settings/users/[id].vue?macro=true";
import { default as indexsc1K4NCaaKMeta } from "/opt/render/project/src/pages/[domain]/settings/users/index.vue?macro=true";
import { default as indexlbhQEpu3LmMeta } from "/opt/render/project/src/pages/[domain]/tasks/index.vue?macro=true";
import { default as tasksTgikgqcQdkMeta } from "/opt/render/project/src/pages/[domain]/tasks.vue?macro=true";
import { default as _91id_93NMqQA7clPgMeta } from "/opt/render/project/src/pages/[domain]/workflows/[id].vue?macro=true";
import { default as activeJCLor573IbMeta } from "/opt/render/project/src/pages/[domain]/workflows/active.vue?macro=true";
import { default as createMY3BZ20XKCMeta } from "/opt/render/project/src/pages/[domain]/workflows/create.vue?macro=true";
import { default as index5ySWpCyrRgMeta } from "/opt/render/project/src/pages/[domain]/workflows/index.vue?macro=true";
import { default as departmentsB2NBrMQq8qMeta } from "/opt/render/project/src/pages/departments.vue?macro=true";
import { default as forgot_45passwordmb5uDEOKzTMeta } from "/opt/render/project/src/pages/forgot-password.vue?macro=true";
import { default as helpH17oDUVH9TMeta } from "/opt/render/project/src/pages/help.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as organisationPegEQ8KdaEMeta } from "/opt/render/project/src/pages/organisation.vue?macro=true";
import { default as registeruUya2x0YcpMeta } from "/opt/render/project/src/pages/register.vue?macro=true";
import { default as usersF3JyCFuZB0Meta } from "/opt/render/project/src/pages/users.vue?macro=true";
export default [
  {
    name: "domain-blueprints-id",
    path: "/:domain()/blueprints/:id()",
    meta: _91id_933tWCISStpCMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/blueprints/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-blueprints-create-done",
    path: "/:domain()/blueprints/create/done",
    meta: donedvXGgcsWVnMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/blueprints/create/done.vue").then(m => m.default || m)
  },
  {
    name: "domain-blueprints-create",
    path: "/:domain()/blueprints/create",
    meta: indexv4OK9yqND2Meta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/blueprints/create/index.vue").then(m => m.default || m)
  },
  {
    name: "domain-blueprints",
    path: "/:domain()/blueprints",
    meta: indexVZPTZy1tMtMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/blueprints/index.vue").then(m => m.default || m)
  },
  {
    name: "domain",
    path: "/:domain()",
    meta: indexry2JKzRL0tMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/index.vue").then(m => m.default || m)
  },
  {
    name: "domain-profile",
    path: "/:domain()/profile",
    meta: profilez1kc1tjKBkMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/profile.vue").then(m => m.default || m)
  },
  {
    name: "domain-settings-departments-id",
    path: "/:domain()/settings/departments/:id()",
    meta: _91id_93CZuFSFUaydMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/settings/departments/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-settings-departments",
    path: "/:domain()/settings/departments",
    meta: index5Cr4nlHza5Meta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/settings/departments/index.vue").then(m => m.default || m)
  },
  {
    name: "domain-settings-organisation",
    path: "/:domain()/settings/organisation",
    meta: organisationhOU0PFhosvMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/settings/organisation.vue").then(m => m.default || m)
  },
  {
    name: "domain-settings-users-id",
    path: "/:domain()/settings/users/:id()",
    meta: _91id_933RFgIImSuyMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/settings/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-settings-users",
    path: "/:domain()/settings/users",
    meta: indexsc1K4NCaaKMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/settings/users/index.vue").then(m => m.default || m)
  },
  {
    name: tasksTgikgqcQdkMeta?.name,
    path: "/:domain()/tasks",
    meta: tasksTgikgqcQdkMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/tasks.vue").then(m => m.default || m),
    children: [
  {
    name: "domain-tasks",
    path: "",
    meta: indexlbhQEpu3LmMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/tasks/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "domain-workflows-id",
    path: "/:domain()/workflows/:id()",
    meta: _91id_93NMqQA7clPgMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/workflows/[id].vue").then(m => m.default || m)
  },
  {
    name: "domain-workflows-active",
    path: "/:domain()/workflows/active",
    meta: activeJCLor573IbMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/workflows/active.vue").then(m => m.default || m)
  },
  {
    name: "domain-workflows-create",
    path: "/:domain()/workflows/create",
    meta: createMY3BZ20XKCMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/workflows/create.vue").then(m => m.default || m)
  },
  {
    name: "domain-workflows",
    path: "/:domain()/workflows",
    meta: index5ySWpCyrRgMeta || {},
    component: () => import("/opt/render/project/src/pages/[domain]/workflows/index.vue").then(m => m.default || m)
  },
  {
    name: "departments",
    path: "/departments",
    component: () => import("/opt/render/project/src/pages/departments.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/opt/render/project/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/opt/render/project/src/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "organisation",
    path: "/organisation",
    component: () => import("/opt/render/project/src/pages/organisation.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/opt/render/project/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/opt/render/project/src/pages/users.vue").then(m => m.default || m)
  }
]
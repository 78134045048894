<template>
  <div class="mt-28 flex flex-col items-center justify-center text-center">
    <div class="flex flex-col items-center">
      <h1 class="mb-3 text-6xl font-black">{{ error?.statusCode }}</h1>
      <p>{{ error?.message }}</p>
      <!-- <p>{{ error?.stack }}</p> -->
      <p class="mb-10">{{ error?.cause }}</p>
      <UButton variant="outline" color="rose" @click="handleError">Clear errors</UButton>
      <UButton variant="ghost" @click="navigateTo('/')">Go to home</UButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps({
  error: Object as () => NuxtError,
})

const handleError = () => clearError({ redirect: '/' })
</script>
